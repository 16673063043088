<template>
    <div>
        <div class="container">
            <section class="section pb-6 mb-6 px-0 pt-2">
                <div class="column p-0 is-half">
                    <h1 class="title l mt-6">Get In Touch</h1>
                    <p
                        class="mb-5"
                    >We would love to hear from you! Let us know what is going on and we will get back to you as soon as we can.</p>
                </div>
                <div class="columns">
                    <div class="column is-7">
                        <ContactForm/>
                    </div>
                    <div class="column is-1"></div>
                    <div class="column">
                        <div class="contact-info">
                            <div class="block">
                                <p class="has-text-weight-bold">General Inquiries</p>
                                <a
                                    href="mailto:info@eduworks.com"
                                    target="_blank"
                                    class="hover-primary"
                                >info@eduworks.com</a>
                            </div>

                            <div class="block">
                                <p class="has-text-weight-bold">Careers</p>
                                <a
                                    href="mailto:jobs@eduworks.com"
                                    target="_blank"
                                    class="hover-primary"
                                >jobs@eduworks.com </a>
                            </div>

                            <div class="block">
                                <div class="icon">
                                    <i class="fas fa-phone"></i>
                                </div>
                                <div>
                                    <a
                                        href="tel:+15417530844"
                                        target="_blank"
                                        class="hover-primary"
                                    >+1 (541) 753-0844</a>
                                </div>
                            </div>
                            <div class="block">
                                <div class="icon">
                                    <i class="fas fa-map-marker-alt"></i>
                                </div>
                                <br />
                                <a
                                    href="https://goo.gl/maps/xzwM7knEgMMQFPZv9"
                                    target="_blank"
                                    class="hover-primary"
                                >
                                    400 SW 4th Street
                                    <br />Suite 110
                                    <br />Corvallis, OR 97333 USA
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <Map />
    </div>
</template>


<script>
import Map from '@/components/map.vue'
import ContactForm from '../components/contactForm.vue'

export default {
    name: 'Contact',
    components: {
        Map,
        ContactForm
    },
    data: () => {
        return {
        }
    },
    metaInfo: {
        // Default subtitle if no subcomponent (%s) is defined by other page views
        title: 'Contact Us',
        // all titles will be injected into this template unless template is set to null
        titleTemplate: 'Eduworks | %s'
    },
    mounted() {
        if (this.$store.state.products.length < 1)
            this.$store.dispatch('getProducts')
    },
    computed: {
        products() {
            return this.$store.state.products
        }
    },
    methods: {
    }
}
</script>
