<template>
    <form @submit.prevent="getFormValues">
        <div class="contact-form">
            <div class="field is-horizontal">
                <div class="field-body">
                    <div class="field">
                        <p class="control">
                            <label for="fname"
                                   class="label">First Name*</label>
                            <input required
                                   class="input has-background-light is-radiusless no-box-shadow"
                                   id="fname"
                                   type="text" />
                        </p>
                    </div>
                    <div class="field">
                        <p class="control">
                            <label for="lname"
                                   class="label">Last Name*</label>
                            <input required
                                   class="input has-background-light is-radiusless no-box-shadow"
                                   id="lname"
                                   type="text" />
                        </p>
                    </div>
                </div>
            </div>
            <div class="field is-horizontal">
                <div class="field-body">
                    <div class="field">
                        <p class="control">
                            <label for="email"
                                   class="label">E-mail Address*</label>
                            <input required
                                   class="input has-background-light is-radiusless no-box-shadow"
                                   id="email"
                                   type="text" />
                        </p>
                    </div>
                </div>
            </div>
            <div class="control mt-3">
                <label for="subject"
                       class="label">Reason for inquiry</label>
                <div class="select is-expanded is-fullwidth">
                    <select class="has-background-light is-radiusless no-box-shadow"
                            id="subject"
                            name="subject">
                        <option disabled
                                selected>Subject</option>
                        <option v-for="(product, index) in products"
                                :key="index"
                                :value="product.name">{{ product.name }}</option>
                        <!-- <option value="cass">CASS</option> -->
                        <!-- <option value="pebl">PeBL</option> -->
                        <option value="govt-contracts">Government Contracts</option>
                        <option value="new-projects">New Project</option>
                        <option value="other">Other</option>
                    </select>
                </div>
            </div>
            <div class="field is-horizontal">
                <div class="field-body">
                    <div class="field">
                        <div class="control mt-3">
                            <label for="message"
                                   class="label">Message*</label>
                            <textarea required
                                      class="textarea has-background-light is-radiusless no-box-shadow"
                                      id="message"
                                      rows="5"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div class="control">
                <input type="submit"
                       :disabled="isSending"
                       value="Send"
                       class="button is-primary is-radiusless has-text-weight-bold is-pulled-right primary-button" />
                <div v-if="sent"
                     class="notification is-primary has-text-weight-bold is-size-6 has-text-5 has-text-centered">
                    <button class="delete"
                            @click="sent = false"></button>Your messages was sent!<i class="fas fa-paper-plane ml-2"></i>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
export default {
    name: 'ContactForm',
    components: {
    },
    data: () => {
        return {
            isSending: false,
            sent: false
        }
    },
    computed: {
        products() {
            return this.$store.state.products
        }
    },
    methods: {
        getFormValues: async function (submitEvent) {
            let subject = submitEvent.target[3].value
            let fname = submitEvent.target[0].value
            const lname = submitEvent.target[1].value
            const email = submitEvent.target[2].value
            const message = submitEvent.target[4].value

            // console.log({
            //         subject: subject,
            //         fname: fname,
            //         lname: lname,
            //         email: email,
            //         message: "first: "+ fname + " \nlast: "+ lname + " \nemail: "+ email + " \n\n" + message
            //     }
            // )

            this.isSending = true
            await fetch(process.env.VUE_APP_SMTP_URL, {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    subject: subject,
                    fname: fname,
                    lname: lname,
                    email: email,
                    message: "first: " + fname + " \nlast: " + lname + " \nemail: " + email + " \n\n" + message
                })
            }).catch((err) => {
                console.alert(err)
            })
            this.isSending = false
            this.sent = true;
            submitEvent.target.reset();
        }
    }
}
</script>